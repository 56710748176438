<div fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="20px">

  <!-- Produto 01 -->
  <mat-card class="product mat-elevation-z14 animated flipInY delay-1s">
    <mat-card-header>
      <div mat-card-avatar class="example-header-image"></div>
      <mat-card-title class="produto-nome">Sofá Veludo</mat-card-title>
      <mat-card-subtitle class="produto-marca">Hipster</mat-card-subtitle>
    </mat-card-header>

    <!-- foto produto -->
    <div #produto_foto class="produto-foto">
      <img mat-card-image src="https://ar.spolus.io/images/client01-sofa.jpeg" alt="sofa verde veludo">
    </div>

    <!-- Descricao do produto -->
    <mat-card-content fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="10px">
      <div fxFlex="70">
        <p>
          Sofá hipster verde com pernas de madeira marrons e finas.
        </p>
        <span>A descrição completa do produto aqui</span>
      </div>
    </mat-card-content>

    <!-- Ações -->
    <mat-card-action fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="10px">
        <button (click)="openPageAR()" fxFlex="70" mat-raised-button color="warn" class="btn-interagir animated shake delay-2s">interagir</button>

        <button mat-fab color="accent" aria-label="lista de desejo">
          <span class="material-icons">favorite_border</span>
        </button>

        <button class="btn-carrinho" mat-fab aria-label="adicionar oa carrinho">
          <span class="material-icons">add_shopping_cart</span>
        </button>
    </mat-card-action>
  </mat-card>

  <!-- Produto 02 -->
  <mat-card class="product mat-elevation-z14 animated flipInY delay-1s">
    <mat-card-header>
      <div mat-card-avatar class="example-header-image"></div>
      <mat-card-title class="produto-nome">Sofá Couro</mat-card-title>
      <mat-card-subtitle class="produto-marca">Industrial</mat-card-subtitle>
    </mat-card-header>

    <!-- foto produto -->
    <div #produto_foto class="produto-foto">
      <img mat-card-image src="https://ar.spolus.io/images/client01-sofa2.jpg" alt="sofa couro antigo">
    </div>

    <!-- Descricao do produto -->
    <mat-card-content fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="10px">
      <div fxFlex="70">
        <p>
          Sofá de couro estilo oldschool industrial reliquia antigo
        </p>
        <span>A descrição completa do produto aqui</span>
      </div>
    </mat-card-content>

    <!-- Ações -->
    <mat-card-action fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="10px">
        <button fxFlex="70" mat-raised-button color="warn" class="btn-interagir animated shake delay-2s">interagir</button>

        <button mat-fab color="accent" aria-label="lista de desejo">
          <span class="material-icons">favorite_border</span>
        </button>

        <button class="btn-carrinho" mat-fab aria-label="adicionar oa carrinho">
          <span class="material-icons">add_shopping_cart</span>
        </button>
    </mat-card-action>
  </mat-card>

</div>
