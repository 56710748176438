import { NgModule, InjectionToken } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { AppComponent } from './app.component';
import { ImageTrackingComponent } from './image-tracking/image-tracking.component';
import { MarkerBasedComponent } from './marker-based/marker-based.component';
import { TipoComponent } from './tipo/tipo.component';

const routes: Routes = [
  {path: "", redirectTo: "app-tipo", pathMatch: "full"},
  {path: "app-tipo", component: TipoComponent},
  {path: "app-image-tracking", component: ImageTrackingComponent},
  {path: "app-marker-based", component: MarkerBasedComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
