import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as screenfull from 'screenfull';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-marker-based',
  templateUrl: './marker-based.component.html',
  styleUrls: ['./marker-based.component.css']
})

export class MarkerBasedComponent implements OnInit, OnDestroy, AfterViewInit {

  url: string = "https://ar.spolus.io/client01/client-mode-0002.html";
  constructor(
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    let rotate;
    // Listen for orientation changes
    window.addEventListener("orientationchange", function() {
      // Announce the new orientation number
      rotate = +this.window.orientation;
      console.log(+window.orientation);
    }, false);

  }

  openPageAR() {
    window.location.href = this.url;
  }

  ngOnDestroy(): void {
  }

}
