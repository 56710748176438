<div fxLayout="column" fxLayoutAlign="center stretch" >

  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div style="text-align: center;">
      <h2 style="margin-top: 100px;">Escolha a forma de visualização</h2>
    </div>
    <div style="text-align: center;">
      <button routerLink="/app-image-tracking" class="btn-foto-produto mat-elevation-z12 animated flipInX delay-1s" mat-raised-button>Via foto do produto ou coleção</button>
      <button routerLink="/app-marker-based" class="btn-marca-gerada mat-elevation-z12 animated flipInX delay-1s" mat-raised-button	>Via marca gerada</button>
    </div>
  </div>
</div>



